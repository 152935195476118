import styled, { css } from "styled-components";
import { pixelToRem } from "../../utils";
import Icons from "../../images/icons";

const customStyles = css<any>`
  ${({ customCss }) => customCss && customCss};
`;

const sharedInputStyles = css<any>`
  font-family: sans-serif;
  box-sizing: border-box;
  line-height: 100%;
  width: 100%;
  min-height: ${pixelToRem(48)};
  margin: 0;
  border: ${pixelToRem(1)} solid black;
  font-size: ${pixelToRem(16)};
  padding: ${pixelToRem(13)} ${pixelToRem(20)};
  border-radius: ${pixelToRem(10)};
  outline: none;
  appearance: none;

  &:focus {
    border-color: ${({ theme }) => theme.color.primary.normal};
  }

  ${({ theme }) =>
    theme &&
    css`
      ${theme.font &&
      css`
        ${theme.font.family &&
        css`
          font-family: ${theme.font.family.primary};
        `};
      `};

      ${theme.color &&
      css`
        border-color: ${theme.color.secondary.normal};

        &:focus {
          border-color: ${theme.color.primary.normal};
        }
      `};
    `};

  ${({ customCss }) => customCss && customCss};
`;

export const A = styled.a<any>`
  ${(props) => {
    return props.customCss;
  }}
`;
export const Footer = styled.footer<any>`
  ${(props) => {
    return props.customCss;
  }}
`;
export const Form = styled.form<any>`
  ${(props) => {
    return props.customCss;
  }}
`;
export const H1 = styled.h1<any>`
  margin-bottom: ${pixelToRem(10)};
  color: ${({ theme }) => theme.text.color.primary.normal};
  font-size: ${pixelToRem(36)};
  font-family: ${({ theme }) => theme.font.family.primary.bold};
  ${customStyles};
  ${(props) => {
    return props.customCss;
  }}
`;
export const H2 = styled.h2<any>`
  margin-bottom: ${pixelToRem(10)};
  color: ${({ theme }) => theme.text.color.secondary.normal};
  font-size: ${pixelToRem(32)};
  font-family: ${({ theme }) => theme.font.family.primary.bold};
  ${customStyles};
  ${(props) => {
    return props.customCss;
  }}
`;
export const H3 = styled.h3<any>`
  margin-bottom: ${pixelToRem(10)};
  color: ${({ theme }) => theme.text.color.primary.normal};
  font-size: ${pixelToRem(20)};
  font-family: ${({ theme }) => theme.font.family.primary.bold};

  @media (min-width: ${({ theme }) =>
      pixelToRem(theme.responsive.tablet.minWidth)}) {
    font-size: ${pixelToRem(22)};
  }

  @media (min-width: ${({ theme }) =>
      pixelToRem(theme.responsive.desktop.minWidth)}) {
    font-size: ${pixelToRem(24)};
  }

  ${customStyles};
  ${(props) => {
    return props.customCss;
  }}
`;
export const Header = styled.div<any>`
  ${(props) => {
    return props.customCss;
  }}
`;
export const HGroup = styled.div<any>`
  ${(props) => {
    return props.customCss;
  }}
`;
export const Img = styled.img<any>`
  ${(props) => {
    return props.customCss;
  }}
`;
export const Input = styled.input<any>`
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  ${sharedInputStyles};
  ${customStyles};
  ${(props) => {
    return props.customCss;
  }}
`;
export const Label = styled.label<any>`
  ${(props) => {
    return props.customCss;
  }}
`;
export const Nav = styled.nav<any>`
  ${(props) => {
    return props.customCss;
  }}
`;
export const Option = styled.option<any>`
  background-color: white;
  ${customStyles};
  ${(props) => {
    return props.customCss;
  }}
`;
export const OptGroup = styled.div<any>`
  background-color: white;
  ${customStyles};
  ${(props) => {
    return props.customCss;
  }}
`;
export const P = styled.p<any>`
  color: ${({ theme }) => theme.text.color.primary.normal};
  font-family: ${({ theme }) => theme.font.family.primary.regular};
  margin-bottom: ${pixelToRem(14)};
  ${customStyles};
  ${(props) => {
    return props.customCss;
  }}
`;
export const Select = styled.select<any>`
  ${sharedInputStyles};
  background: url(${Icons.SelectArrowIcon}) no-repeat right ${pixelToRem(14)}
    top 50% white;
  cursor: pointer;
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  ${customStyles};
  ${(props) => {
    return props.customCss;
  }}
`;
export const Span = styled.span<any>`
  font-family: ${({ theme }) => theme.font.family.primary.regular};
  ${customStyles};
  ${(props) => {
    return props.customCss;
  }}
`;
export const Strong = styled.strong<any>`
  font-family: ${({ theme }) => theme.font.family.primary.bold};
`; // Import from @meema.Elements
export const TextArea = styled.textarea<any>`
  ${(props) => {
    return props.customCss;
  }}
`;
export const View = styled.div<any>`
  ${(props) => {
    return props.customCss;
  }}
`;
export const Wrapper = styled.div<any>`
  ${(props) => {
    return props.customCss;
  }}
`;
export const WrapperHtml = styled.div<any>`
  color: ${({ theme }) => theme.text.color.primary.normal};
  font-family: ${({ theme }) => theme.font.family.primary.regular};

  p {
    margin-top: ${pixelToRem(12)};
    margin-bottom: ${pixelToRem(12)};
    font-size: ${pixelToRem(16)};
    line-height: 1.2;

    @media (min-width: ${({ theme }) =>
        pixelToRem(theme.responsive.tablet.minWidth)}) {
      font-size: ${pixelToRem(18)};
    }
  }

  strong {
    font-family: ${({ theme }) => theme.font.family.primary.bold};
  }

  ${customStyles};
  ${(props) => {
    return props.customCss;
  }}
`;

export const Button = styled.button<any>`
  border: 0px;
  cursor: pointer;
  outline: none;
  padding: 0 ${pixelToRem(20)};
  min-height: ${pixelToRem(48)};
  color: white;
  background-color: ${({ theme }) => theme.color.primary.normal};
  border-radius: ${pixelToRem(5)};
  font-size: ${pixelToRem(18)};
  font-family: ${({ theme }) => theme.font.family.primary.bold};
  white-space: nowrap;

  &:hover {
    background-color: ${({ theme }) => theme.color.primary.dark};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.color.secondary.normal};
    opacity: 1;

    &:hover {
      background-color: ${({ theme }) => theme.color.secondary.normal};
    }
  }

  ${customStyles};
  ${(props) => {
    return props.customCss;
  }}
`;

export const MPSecurityField = styled.div<any>`
  height: 3rem;
  background: white;
  ${sharedInputStyles};
  ${customStyles};
  ${(props) => {
    return props.customCss;
  }}

  iframe {
  }
`;

const _ = {
  A,
  Button,
  Footer,
  Form,
  H1,
  H2,
  H3,
  Header,
  HGroup,
  Img,
  Input,
  Label,
  Nav,
  Option,
  OptGroup,
  P,
  Select,
  Span,
  Strong,
  TextArea,
  View,
  Wrapper,
  WrapperHtml,
  MPSecurityField,
};

export default _;
