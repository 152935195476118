import Elements from "../Shared/Elements";
import { pixelToRem, CustomCSSType } from "meema.utils";
import styled, { css } from "styled-components";
import General from "./General";
import GreenpeaceLogo from "../../images/greenpeace-logo.svg";

const Spinner = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border-bottom: 2px solid #6aca25;
  border-left: 2px solid #6aca25;
  border-top: 2px solid #6aca25;

  border-right: 2px solid transparent;
  animation: spin 1s infinite linear;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export const Logo: React.FunctionComponent<{
  color?: string;
  customCss?: CustomCSSType;
  country?: string;
}> = ({ customCss, color, country = null }) => (
  <Elements.A
    href={
      country ? `https://greenpeace.org/${country}` : "https://greenpeace.org"
    }
    customCss={css`
      ${customCss && customCss};
    `}
  >
    <Elements.Wrapper
      customCss={css`
        width: ${pixelToRem(160)};
        height: ${pixelToRem(30)};
        background-color: ${({ theme }) => theme.color.primary.normal};
        ${color &&
        css`
          background-color: ${color};
        `}
        mask-image: url(${GreenpeaceLogo});
        mask-size: 100%;
        mask-repeat: no-repeat;
      `}
    />
  </Elements.A>
);

export const Loader: React.FunctionComponent<{
  mode?: "light" | "default";
}> = ({ mode }) => (
  <Elements.Wrapper
    customCss={css`
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
    `}
  >
    <Spinner />
  </Elements.Wrapper>
);

const defaults = {
  Loader,
  Logo,
  General,
};

export default defaults;
