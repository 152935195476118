import Elements from "./Elements";
import styled, { css } from "styled-components";
import { pixelToRem } from "../../utils";
import Icons from "../../images/icons";

const Subtitle = styled(Elements.H2)`
  margin-bottom: ${pixelToRem(10)};
  font-size: ${pixelToRem(24)};
  font-family: ${({ theme }) => theme.font.family.primary.light};

  ${({ customCss }) => customCss && customCss};
`;

const WarningText = styled(Elements.P)<{
  highlighted?: boolean;
  textAlign?: string;
}>`
  font-size: ${pixelToRem(12)};
  margin-bottom: ${pixelToRem(10)};
  background-color: #6aca2550;
  border-radius: 12px;
  padding: 14px 16px;
  text-align: center;
  line-height: 1.2;

  @media (min-width: ${({ theme }) =>
      pixelToRem(theme.responsive.tablet.minWidth)}) {
    font-size: ${pixelToRem(14)};
  }

  ${({ highlighted }) =>
    highlighted &&
    css`
      font-family: ${({ theme }) => theme.font.family.primary.bold};
    `};

  ${({ textAlign }) =>
    textAlign &&
    css`
      text-align: ${textAlign};
    `};
`;

const Text = styled(Elements.P)<{ highlighted?: boolean; textAlign?: string }>`
  font-size: ${pixelToRem(16)};
  margin-bottom: ${pixelToRem(10)};

  @media (min-width: ${({ theme }) =>
      pixelToRem(theme.responsive.tablet.minWidth)}) {
    font-size: ${pixelToRem(24)};
  }

  ${({ highlighted }) =>
    highlighted &&
    css`
      font-family: ${({ theme }) => theme.font.family.primary.bold};
    `};

  ${({ textAlign }) =>
    textAlign &&
    css`
      text-align: ${textAlign};
    `};
`;

const Link = styled(Elements.A)`
  text-decoration: underline;
  margin-bottom: ${pixelToRem(5)};
  font-family: ${({ theme }) => theme.font.family.primary.regular};

  @media (min-width: ${({ theme }) =>
      pixelToRem(theme.responsive.tablet.minWidth)}) {
    margin-bottom: 0;

    &:after {
      content: "|";
      margin-right: ${pixelToRem(10)};
      margin-left: ${pixelToRem(10)};
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }
`;

const ButtonLink = styled(Elements.A)<{
  format?: "contained" | "outlined" | "text";
}>`
  display: inline-flex;
  padding: ${pixelToRem(5)} ${pixelToRem(30)};
  color: white;
  background-color: ${({ theme }) => theme.color.primary.normal};
  border-radius: ${pixelToRem(5)};
  font-family: ${({ theme }) => theme.font.family.primary.bold};
  font-size: ${pixelToRem(18)};
  white-space: nowrap;
  transition: all 250ms ease;

  &:hover {
    background-color: ${({ theme }) => theme.color.primary.dark};
  }

  ${({ format }) =>
    format === "text" &&
    css`
      background-color: transparent;
      color: ${({ theme }) => theme.color.primary.normal};
      text-decoration: underline;
      padding: 0;

      &:hover {
        background-color: transparent;
        box-shadow: none !important;
      }
    `}

  ${({ customCss }) => customCss && customCss};
`;

const ButtonClose = styled(Elements.Button)`
  display: inline-flex;
  align-items: center;
  padding: 0;
  z-index: 1;
  background-color: transparent;

  &:before {
    display: flex;
    width: ${pixelToRem(20)};
    height: ${pixelToRem(20)};
    background-image: url(${Icons.XIcon});
    background-size: ${pixelToRem(20)} ${pixelToRem(20)};
    background-repeat: no-repeat;
    content: "";
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
    box-shadow: none !important;
  }

  ${({ customCss }) => customCss && customCss};
`;

const defaults = {
  Text,
  Subtitle,
  ButtonClose,
  ButtonLink,
  Link,
  WarningText,
};

export default defaults;
